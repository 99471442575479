import React from 'react'
import { TextField } from '@material-ui/core'
import { BotField, Button, Form, Row } from './shared'

const Subscribe = () => {
  return (
    <>
      <Form 
        name="subscribe" 
        method="POST" 
        action='/thanks' 
        data-netlify="true" 
        data-netlify-honeypot="honey-bot" 
        // data-netlify-recaptcha='true'
      >
        <input type="hidden" name="form-name" value="subscribe" />
        <BotField type="hidden" name="honey-bot" defaultValue='' />
        <Row>
          <TextField variant='outlined' label="First Name" name="fname" defaultValue=''></TextField>
          <TextField variant='outlined' label="Last Name" name="lname" defaultValue=''></TextField>
          <TextField variant='outlined' label="Email" name="email" type='email' defaultValue='' required></TextField>
        </Row>
        {/* <Row>
          <FormControl component="fieldset">
            <FormLabel component="legend">Interest</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={true} onChange={() => console.log('checked')} name="webdev" />}
                label="Web Development"
              />
              <FormControlLabel
                control={<Checkbox checked={false} onChange={() => console.log('check')} name="dance" />}
                label="Partner Dance"
              />
            </FormGroup>
          </FormControl>
        </Row> */}
        <Row>
            <Button type="submit">Subscribe</Button>
        </Row>
      </Form>
    </>
  )
}

export default Subscribe
