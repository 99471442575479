import styled from 'styled-components'
import theme from './../../utils/colors';

export const Form = styled.form`
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  &:not(:first-child) {
    margin-top: 16px;
  }
  > .MuiFormControl-root {
    flex: 1 1 30%;
    min-width: 250px;
    display: flex;
  }
  .MuiFormLabel-root,
  .MuiInputBase-root {
    font-family: "Poppins", Arial, sans-serif;
  }
`

export const BotField = styled.input`
  display: none; // don't show to screenreaders 
`

export const Button = styled.button`
  transition: background-color 0.2s ease-in-out;
  border-radius: 0;
  border: none;
  background-color: ${theme.light.primary};
  padding: 0 1em;
  line-height: 3;
  font-weight: 700;
  color: ${theme.light.primaryContrast};
  cursor: pointer;
  &:hover, &:active, &:focus {
    background-color: ${theme.light.primaryAlt};
  }
`
